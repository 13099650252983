<template>
    <div>
        <h2 class="dark--text font-weight-bold subheading pt-1 text-xs-center">CREATE NEW EVENT</h2>
        <v-divider></v-divider>

        <v-card color="white pa-3" style="" >
            <p class="dark--text subheading pa-0 ma-0"><b>Amount Saved:</b> <span> 15450 FCFA</span></p>
            <v-divider></v-divider>
            <p class="dark--text caption pt-2 ma-0 text-xs-center">The transaction will be updated to your campaign after the transaction id is verified</p>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>            
                    <v-text-field class="pa-0 mt-2"
                        id="save_amount"
                        label="Enter amont you have sent (F CFA)"
                        single-line
                        color="primary"
                    ></v-text-field>

                    <v-text-field class="pa-0"
                        id="phone_number"
                        label="Enter Phone Number Used"
                        single-line
                        color="primary"
                    ></v-text-field>
                    <v-text-field class="pa-0"
                        id="transaction_id"
                        label="Enter Transaction ID"
                        single-line
                        color="primary"
                    ></v-text-field>
                </v-flex>
            </v-layout>
            <div class=" text-xs-center">
                <v-btn class="blue" small round color="primary" >
                    <span> Submit</span>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                completedCampaigns: [
                    {
                        title: 'New Telephone',
                        creation_date: '02/02/2022 at 10:43',
                        objective: '30,000 FCFA by the 13/04/2022',
                        link: '/campaigns/1',
                        key: 'xHI654Gik'
                    },
                    {
                        title: 'Christmas',
                        creation_date: '02/02/2022 at 10:43',
                        objective: 'By the 23/12/2022',
                        link: '/campaigns/2',
                        key: 'x#$fg6k'
                    },
                    {
                        title: 'Health Campaign',
                        creation_date: '12/06/2021 at 10:43',
                        objective: '13,500 FCFA',
                        link: '/campaigns/3',
                        key: 'xHI6GfSD'
                    }
                ],
            }
        },

    }
</script>
